import React from "react";
import { Link } from "react-router-dom";
import styles from "../SideBar.module.scss";

function SideBarContentHome() {
  return (
    <>
      <img className={styles.img} src="/assets/img/intro.jpg" alt="Baner" />
      <div className={styles.profile}>
        <Link to="/" style={{ textAlign: "center" }}>
          <img className={styles.avatar} src="/logo.svg" alt="Logo" />
        </Link>
        <div className={styles.initials}>Евгений Рязанов</div>
        <div className={styles.title}>Мастер отделочно-декоративных работ</div>
        <div className={styles.text}>
          <p>
            Нанесением покрытий занимаюсь более 20 лет. Работал в разных сферах
            отделки от начальной подготовки стен до финишного обустройства
            помещений. Имею большой опыт работы как с кропотливой отделкой
            небольших помещений, так и с облагораживанием масштабных фасадов.
            Последнее время специализируюсь на нанесении всевозможных
            декоративных покрытий.
          </p>
        </div>
      </div>
    </>
  );
}

export default SideBarContentHome;
