import React from "react";
import axios from "../../../redux/axios";

import Button from "../../Button";
import styles from "./Communication.module.scss";

const CommunicationForm: React.FC<{ clouseModal?: () => void }> = ({
  clouseModal,
}) => {
  const textRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [contact, setContact] = React.useState("");
  const [name, setName] = React.useState("");
  const [text, setText] = React.useState("");
  const [submit, setSubmit] = React.useState(false);
  const [connect, setConnect] = React.useState(false);
  const onSubmit = async () => {
    setConnect(true);
    const massage = { contact, name, text };
    axios.post("/form", { massage });
    setConnect(false);

    setSubmit(false);
    setContact("");
    setName("");
    setText("");
    if (clouseModal) clouseModal();
    return alert("Сообщение успешно отправлено");
  };

  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textArea = textRef.current;
    setText(event.target.value);
    if (textArea) {
      textArea.style.height = textArea.scrollHeight + "px";
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();
        setSubmit(true);
        if (contact.length >= 5 && name.length >= 3 && text.length >= 5)
          onSubmit();
      }}
    >
      <div className={styles.item}>
        <div>Форма обратной связи</div>
      </div>
      <div className={styles.item}>
        <input
          className={`${styles.input} ${
            submit && name.length < 3 ? styles.error : ""
          }`}
          type="text"
          placeholder="Ваше имя"
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {submit && name.length < 3 && (
          <span className={styles.errorMsg}>Введите не менее 3 символов</span>
        )}
      </div>
      <div className={styles.item}>
        <input
          className={`${styles.input} ${
            submit && contact.length < 5 ? styles.error : ""
          }`}
          type="text"
          placeholder="Ваш контакт для связи"
          required={true}
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
        {submit && contact.length < 5 && (
          <span className={styles.errorMsg}>
            Введите корректный e-mail или телефон
          </span>
        )}
      </div>

      <div className={styles.item}>
        <textarea
          name="firstName"
          required={true}
          onChange={onChangeTextArea}
          className={styles.textarea}
          value={text}
          placeholder="Напишите что-нибудь"
        />
        {submit && text.length < 5 && (
          <span className={styles.errorMsg}>Введите сообщение</span>
        )}
        <span className={styles.line}></span>
      </div>
      {connect ? (
        <img style={{ width: 80 }} src="/preloaderLogo.svg" alt="Logo" />
      ) : (
        <Button type="submit">Отправить</Button>
      )}

      <ul className={styles.footer}>
        <li>e-mail: eur9516@mail.ru</li>
        <li>
          тел:
          <br />
          <a className={styles.link} href="tel:+79233429516">
            +7 923-342-95-16
          </a>
        </li>
      </ul>
    </form>
  );
};

export default CommunicationForm;
