import React from "react";
import PreviewArticle from "./ArticleBlock/Preview";
import Article from "./ArticleBlock";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Status } from "../../redux/feth/types";
import Skeleton from "./ArticleBlock/PreviewSkeleton";
import styles from "./Articles.module.scss";
import { Helmet } from "react-helmet";

export enum ArticleCategory {
  coatings = "Декоративные покрытия",
  directions = "Направления в дизайне",
}
export const ListArticleCategory = Object.entries(ArticleCategory).map(
  ([key, value]) => ({ key, value })
);

function Articles() {
  const { items, status } = useSelector(
    (state: RootState) => state.data.article
  );
  const { id } = useParams();
  const pathname = useLocation().pathname;

  React.useEffect(() => {
    window.scroll(0, 0);
  }, [status]);

  const GetContent = () => {
    if (id) {
      return (
        <>
          <Helmet>
            <title>
              Полезные статьи для правильного подбора декоративного покрытия
            </title>
          </Helmet>
          <Article key={"article" + id} _id={id} />
        </>
      );
    }
    /*    if (category)
      return items
        .filter((obj) => obj.category === category)
        .map((obj, index) => (
          <PreviewArticle key={"articlelist" + index} {...obj} />
        )); */
    if (!items.length)
      return [...new Array(4)].map((_, index) => (
        <Skeleton key={"Skeleton" + index} />
      ));
    if (pathname.indexOf("article") > 0)
      return (
        <>
          <Helmet>
            <title>
              Полезные статьи для правильного подбора декоративного покрытия
            </title>
            <meta
              name="description"
              content="Разновидностей декоративной штукатурки очень много, и каждое покрытие имеет свои особенности. Благодаря чему можн подобрать покрытие под каждый интерьер, комнату или постройку, учитывая их основное назначение."
            />
          </Helmet>
          <h1 className={styles.title}>Интересно почитать</h1>
          {items.map((_, index) => (
            <PreviewArticle key={"articlelist" + index} {...items[index]} />
          ))}
        </>
      );
    return (
      <>
        <h2 className={styles.title}>Интересно почитать</h2>
        {[...new Array(Math.min(4, items.length))].map((_, index) => (
          <PreviewArticle key={"articlelist" + index} {...items[index]} />
        ))}
      </>
    );
  };

  return (
    <section>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {GetContent()}
        {status === Status.ERROR && (
          <div style={{ marginBottom: 50, width: "100%", textAlign: "center" }}>
            Возникла ошибка при загрузке. Попробуйте перезагрузить страницу
          </div>
        )}
      </div>
    </section>
  );
}

export default Articles;
