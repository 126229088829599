import React from "react";
import { IPrototype } from "../../../redux/feth/types";
import Modal from "../../Modal";
import styles from "../Prototype.module.scss";
import ModalContent from "./ModalContent";

const Prototype: React.FC<IPrototype> = ({
  description,
  title,
  img_preview,
  img_all,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <div className={styles.item} onClick={() => setShowModal(true)}>
        <img className={styles.img} src={img_preview} alt={title} />
        <div className={styles.square}>
          <div>
            <p>{title}</p>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          onClickClose={() => setShowModal(false)}
          width={"90vw"}
          height={"90vh"}
        >
          <ModalContent
            items={img_all}
            description={description}
            title={title}
          />
        </Modal>
      )}
    </>
  );
};

export default Prototype;
