import classNames from "classnames";
import React from "react";
import styles from "./modal.module.scss";
type ModalProps = {
  children: React.ReactNode;
  /* Контент */
  onClickClose: () => void;
  /* Функция отвечающая за деактивацию State */
  width: number | string;
  /* Ширина модального окна */
  height: number | string;
  /* Высота модального окна */
  form?: boolean;
};
const Modal: React.FC<ModalProps> = ({
  children,
  onClickClose,
  width,
  height,
  form,
}) => {
  const [clientY, setClientY] = React.useState<number | null>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);

  const TouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const modalRect = modalRef.current;
    if (
      modalRect &&
      (modalRect.scrollTop === 0 ||
        modalRect.scrollTop + modalRect.clientHeight === modalRect.scrollHeight)
    )
      setClientY(event.touches[0].clientY);
  };
  const TouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const modalRect = modalRef.current;
    if (
      clientY &&
      modalRect &&
      (modalRect.scrollTop === 0 ||
        modalRect.scrollTop + modalRect.clientHeight ===
          modalRect.scrollHeight) &&
      modalRef.current?.style
    ) {
      if (clientY && modalRef.current?.style) {
        const style = modalRef.current.style;
        const deltaY = event.touches[0].clientY - clientY;
        style.transform = "translateY(" + deltaY + "px)";
        style.opacity = String(1 - Math.abs(deltaY / 200));
      }
    } else setClientY(null);
  };
  const TouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const modalRect = modalRef.current;
    if (
      modalRect &&
      (modalRect.scrollTop === 0 ||
        modalRect.scrollTop + modalRect.clientHeight === modalRect.scrollHeight)
    ) {
      if (
        clientY &&
        Math.abs(event.changedTouches[0].clientY - clientY) > 180
      ) {
        onClickClose();
      } else if (modalRef.current?.style) {
        const style = modalRef.current.style;
        style.transform = "translateY(" + 0 + "px)";
        style.opacity = String(1);
      }
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={styles.mask} onClick={onClickClose}>
      <div
        ref={modalRef}
        onClick={(event) => event.stopPropagation()}
        className={styles.wrapper}
        onTouchStart={TouchStart}
        onTouchMove={TouchMove}
        onTouchEnd={TouchEnd}
        style={{ width, height }}
      >
        {children}
        <button
          className={classNames(styles.close, { [styles.form]: form })}
          type="button"
          onClick={onClickClose}
        />
      </div>
    </div>
  );
};

export default Modal;

/*import React from "react";
import styles from "./modal.module.scss";
type ModalProps = {
  children: React.ReactNode;
  buttonChildren: React.ReactNode;
  onClickClose: () => void;
};
const Modal: React.FC<ModalProps> = ({
  children,
  buttonChildren,
  onClickClose,
}) => {
  const [clientY, setClientY] = React.useState(0);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const MouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current?.style && clientY) {
      const style = modalRef.current.style;
      const deltaY = event.clientY - clientY;
      style.transform = "translateY(" + deltaY + "px)";
      style.opacity = String(1 - Math.abs(deltaY / 200));
    }
  };
  const MouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current?.style) {
      if (Math.abs(event.clientY - clientY) > 100 || clientY === 0)
        onClickClose();
      const style = modalRef.current.style;
      style.transform = "translateY(0px)";
      style.opacity = "1";
      setClientY(0);
    }
  };
  const OutPage = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.relatedTarget === null ||
        event.relatedTarget instanceof HTMLHtmlElement) &&
      clientY !== 0
    )
      onClickClose();
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  //
  return (
    <div className={styles.mask} onMouseUp={MouseUp} onMouseOut={OutPage}>
      <div
        ref={modalRef}
        className={styles.wrapper}
        onMouseDown={(event) => {
          event.preventDefault();
          setClientY(event.clientY);
        }}
        onMouseMove={MouseMove}
      >
        <button className={styles.close} type="button" onClick={onClickClose}>
          {buttonChildren}
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;*/
