import React from "react";
import styles from "../Articles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import fetchArticle from "../../../redux/feth/asynkArticle";
import { useAppDispatch } from "../../../redux/store";
import { setCategoryArticle } from "../../../redux/filter/slice";
interface ArticleProps {
  title: string;
  preview: string;
  tags: { title: string; type: string }[];
  _id: string;
  img: string;
}
const PreviewArticle: React.FC<ArticleProps> = ({
  img,
  preview,
  _id,
  title,
  tags,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <article className={styles.preview}>
      <Link className={styles.banner} key={_id} to={`/article/${_id}`}>
        <img src={img} alt={title} />
      </Link>
      <div className={styles.content}>
        <div className={styles.title}>
          <Link key={_id} to={`/article/${_id}`}>
            {title}
          </Link>
        </div>

        <div className={styles.description}>{preview}</div>

        <ul className={styles.tags}>
          {tags.map((obj, index) => (
            <li
              key={`tags` + index}
              className={styles.item}
              onClick={() => {
                navigate("/article");
                dispatch(setCategoryArticle(obj.type));
                dispatch(
                  fetchArticle({
                    tags: obj.type,
                  })
                );
              }}
            >
              {obj.title}
            </li>
          ))}
        </ul>

        <div className={styles.footer}>
          <Link to={`/article/${_id}`}>Читать полностью...</Link>
        </div>
      </div>
    </article>
  );
};

export default PreviewArticle;
