import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchArticle from "../../../redux/feth/asynkArticle";
import { setCategoryArticle } from "../../../redux/filter/slice";
import { RootState, useAppDispatch } from "../../../redux/store";

import { ListArticleCategory } from "../../Articles";
import styles from "../SideBar.module.scss";

function SidebarContentArticle() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { category } = useSelector((state: RootState) => state.filter.article);
  return (
    <ul className={styles.nav}>
      {ListArticleCategory.map((obj, index) => (
        <li key={obj.key} className={styles.item}>
          <div
            className={classNames(styles.link, {
              [styles.active]: obj.key === category,
            })}
            onClick={() => {
              dispatch(fetchArticle({ category: obj.key }));
              dispatch(setCategoryArticle(obj.key));

              navigate("/article");
            }}
          >
            {obj.value}
          </div>
        </li>
      ))}
    </ul>
  );
}

export default SidebarContentArticle;
