import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";
import {
  IAdvantage,
  IApartament,
  IPrototype,
  SearchParams,
  TypePayload,
} from "./types";
export const actionFetch = (
  update: boolean,
  page: number,
  typePayload: TypePayload,
  limit: number,
  type?: string,
  subtype?: string
) => {
  return fetchItems({
    update,
    typeItems: typePayload,
    page: String(page),
    limit: String(limit),
    decorType: type === "all" ? "" : type,
    decorSubType: subtype === "all" ? "" : subtype,
  });
};
const fetchItems = createAsyncThunk<
  {
    data: IAdvantage[] | IApartament[] | IPrototype[];
    typeItems: TypePayload;
    update: boolean;
    nextPage: boolean;
  },
  SearchParams
>("items/fetchItemsStatus", async (params) => {
  const { typeItems, page, limit, update, decorType, decorSubType } = params;

  const { data } = await axios.get<{
    item: IAdvantage[] | IApartament[] | IPrototype[];
    nextPage: boolean;
  }>(`/${typeItems}`, {
    headers: {
      page: page,
      limit: limit,
      decorType: decorType || "",
      decorSubType: decorSubType || "",
    },
  });

  return {
    data: data.item,
    typeItems,
    update,
    nextPage: data.nextPage,
  };
});

export default fetchItems;
