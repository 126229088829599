import React from "react";
import styles from "./Easel.module.scss";

//import SlidesList from "./SlidesList";
interface IEasel {
  autoPlayTime: number;
  items: string[];
  width: string;
}

const Easel: React.FC<IEasel> = ({ items, autoPlayTime, width }) => {
  const [activeImg, setActiveImg] = React.useState(0);
  React.useEffect(() => {
    if (items.length < 2) return;
    const interval = setInterval(() => {
      setActiveImg((current) => {
        const res = current === items.length - 1 ? 0 : current + 1;
        return res;
      });
    }, autoPlayTime);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leftImg = activeImg ? activeImg - 1 : items.length - 1;
  const rightImg = activeImg === items.length - 1 ? 0 : activeImg + 1;
  const nextImg = (activeImg + 2) % items.length;
  const prevImg = (activeImg - 2 + items.length) % items.length;
  return (
    <div className={styles.root}>
      <div
        className={`${styles.item} ${styles.prev}`}
        key={`${prevImg}_img`}
        style={{ width: width }}
      >
        <div className={styles.wrapper}>
          <img src={items[prevImg]} className={styles.left_img} alt="Easel" />
          <img src={items[prevImg]} className={styles.right_img} alt="Easel" />
        </div>
      </div>
      <div
        className={`${styles.item} ${styles.left}`}
        key={`${leftImg}_img`}
        style={{ width: width }}
      >
        <div className={styles.wrapper}>
          <img src={items[leftImg]} className={styles.left_img} alt="Easel" />
          <img src={items[leftImg]} className={styles.right_img} alt="Easel" />
        </div>
      </div>

      <div
        className={styles.item}
        key={`${activeImg}_img`}
        style={{ width: width }}
      >
        <div className={styles.wrapper}>
          <img src={items[activeImg]} className={styles.left_img} alt="Easel" />
          <img
            src={items[activeImg]}
            className={styles.right_img}
            alt="Easel"
          />
        </div>
      </div>

      <div
        className={`${styles.item} ${styles.right}`}
        key={`${rightImg}_img`}
        style={{ width: width }}
      >
        <div className={styles.wrapper}>
          <img src={items[rightImg]} className={styles.left_img} alt="Easel" />
          <img src={items[rightImg]} className={styles.right_img} alt="Easel" />
        </div>
      </div>
      <div className={`${styles.item} ${styles.next}`} key={`${nextImg}_img`}>
        <div className={styles.wrapper}>
          <img src={items[nextImg]} className={styles.left_img} alt="Easel" />
          <img src={items[nextImg]} className={styles.right_img} alt="Easel" />
        </div>
      </div>
    </div>
  );
};

export default Easel;
