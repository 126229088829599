import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { actionFetch } from "../../redux/feth/asyncAction";
import fetchArticle from "../../redux/feth/asynkArticle";
import { TypePayload } from "../../redux/feth/types";
import { setCategoryArticle, setSortPrototype } from "../../redux/filter/slice";
import { ListTypeEnum, TypePrototypeEnum } from "../../redux/filter/types";
import { RootState, useAppDispatch } from "../../redux/store";
import { ListArticleCategory } from "../Articles";
import Button from "../Button";
import CommunicationForm from "../Form/Communication";
import Modal from "../Modal";
import styles from "./Header.module.scss";

const Header: React.FC<{
  mobile: boolean;
  showSideBar: boolean;
  setShowSideBar: () => void;
}> = ({ mobile, showSideBar, setShowSideBar }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const { category } = useSelector((state: RootState) => state.filter.article);

  const [activeNav, setActiveNav] = React.useState(false);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const onClickLoadPrototype = (newtype: string) => {
    setActiveNav(false);
    dispatch(
      actionFetch(
        true,
        1,
        TypePayload.PROTOTYPE,
        6,
        newtype === "все" ? "" : newtype,
        ""
      )
    );

    dispatch(setSortPrototype({ title: newtype, type: "type" }));
    if (pathname.indexOf("prototype") <= 0) navigate("/prototype");
  };
  return (
    <>
      <header
        className={styles.root}
        style={mobile ? { zIndex: 101 } : { zIndex: 99 }}
      >
        {" "}
        <div className={styles.left}>
          {mobile ? (
            <button
              className={`${styles.burger} ${showSideBar ? styles.clouse : ""}`}
              type="button"
              onClick={() => setShowSideBar()}
            >
              <span>Открыть навигацию</span>
            </button>
          ) : (
            <ul className={styles.list}>
              <li className={styles.item}>
                <Link
                  className={classNames(styles.link, {
                    [styles.active]: pathname === "/",
                  })}
                  to="/"
                >
                  Главная
                </Link>
              </li>
              <li className={styles.item}>
                <Link
                  onMouseOver={() => setActiveNav(true)}
                  className={classNames(styles.link, styles.has_subnav, {
                    [styles.active]: pathname.indexOf("prototype") > 0,
                  })}
                  to={`/prototype`}
                >
                  Образцы
                </Link>

                {activeNav && (
                  <ul className={styles.subnav}>
                    {ListTypeEnum.filter((e) => {
                      return e.title !== TypePrototypeEnum.all;
                    }).map(
                      (obj) =>
                        obj.key && (
                          <li key={obj.key}>
                            <div
                              className={styles.sublink}
                              onClick={() => onClickLoadPrototype(obj.title)}
                            >
                              {obj.title}
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </li>
              <li className={styles.item}>
                <Link
                  onMouseOver={() => {
                    setActiveNav(true);
                  }}
                  onClick={() => {
                    dispatch(fetchArticle({}));
                    dispatch(setCategoryArticle(""));
                  }}
                  className={classNames(styles.link, styles.has_subnav, {
                    [styles.active]: pathname.indexOf("article") > 0,
                  })}
                  to={`/article`}
                >
                  Статьи
                </Link>

                {activeNav && (
                  <ul className={styles.subnav}>
                    {ListArticleCategory.map(
                      (obj) =>
                        obj.key && (
                          <li key={obj.key}>
                            <div
                              onClick={() => {
                                setActiveNav(false);
                                dispatch(fetchArticle({ category: obj.key }));
                                dispatch(setCategoryArticle(obj.key));
                                if (pathname !== "/article")
                                  navigate("/article");
                              }}
                              className={classNames(styles.sublink, {
                                [styles.active]: obj.key === category,
                              })}
                            >
                              {obj.value}
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </li>
            </ul>
          )}
        </div>
        <div className={styles.right}>
          {showSideBar && mobile && (
            <div className={styles.header_text}>Декоративные покрытия</div>
          )}
          {(!showSideBar || !mobile) && (
            <Button onClick={() => setShowModal(true)}>
              Связаться со мной
            </Button>
          )}
          <a className={styles.contact} href="tel:+79233429516">
            тел: +7 923-342-95-16
          </a>
        </div>
      </header>
      {showModal && (
        <Modal
          onClickClose={() => setShowModal(false)}
          width={"auto"}
          height={"auto"}
          form={true}
        >
          <div className={styles.form}>
            <CommunicationForm clouseModal={() => setShowModal(false)} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Header;
