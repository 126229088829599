import classNames from "classnames";
import React from "react";
import { setSortPrototype } from "../../redux/filter/slice";
import { PayloadSort } from "../../redux/filter/types";
import { useAppDispatch } from "../../redux/store";
import styles from "./sort.module.scss";

interface ISortProps {
  activeSort: PayloadSort;
  sortList: PayloadSort[];
  title: string;
}
const Sort: React.FC<ISortProps> = React.memo(
  ({ title, activeSort, sortList }) => {
    const dispatch = useAppDispatch();
    const [visibileSort, setVisibileSort] = React.useState(false);
    const sortRef = React.useRef<HTMLDivElement>(null);

    const toggleVisiblePopup = (event: React.MouseEvent) => {
      setVisibileSort(!visibileSort);
    };

    const handleOutsideClick = (event: any) => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(sortRef.current)) {
        setVisibileSort(false);
      }
    };

    React.useEffect(() => {
      document.body.addEventListener("click", handleOutsideClick);
      return () => {
        document.body.addEventListener("click", handleOutsideClick);
      };
    }, []);

    const onClickSort = (obj: PayloadSort) => {
      dispatch(setSortPrototype(obj));
      setVisibileSort(false);
    };
    return (
      <div ref={sortRef} onClick={toggleVisiblePopup} className={styles.sort}>
        <b>{title}:</b>
        <span>{activeSort.title}</span>
        {visibileSort && (
          <div className={styles.popup}>
            <ul>
              {sortList.map((obj) => (
                <li
                  key={obj.title}
                  onClick={() => onClickSort(obj)}
                  className={classNames({
                    [styles.active]: obj.title === activeSort.title,
                  })}
                >
                  {obj.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
);

export default Sort;
