import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./fonts/Pacifico-Regular.ttf";
import "./scss/index.scss";
import MainLayout from "./layout/Wrapper";
import Preloader from "./layout/Preloader";
import Articles from "./pages/Articles";
import axios from "axios";
import NotFound from "./pages/NotFound";
const Home = React.lazy(
  () => import(/* webpackChunkName: "Home" */ "./pages/Home")
);

const Samples = React.lazy(
  () => import(/* webpackChunkName: "Samples" */ "./pages/Samples")
);

const App: React.FC = () => {
  const pathname = useLocation().pathname;
  console.log = console.warn = console.error = () => {};

  React.useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);
  React.useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then((res) =>
      axios.get(`${process.env.REACT_APP_API_URL}/ip`, {
        headers: {
          ip: res.data.IPv4,
        },
      })
    );
  }, []);
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<Preloader />}>
              <Home />
            </Suspense>
          }
        />

        <Route path="article" element={<Articles />}>
          <Route path=":id" element={<Articles />} />
        </Route>
        <Route
          path="prototype"
          element={
            <Suspense fallback={<Preloader />}>
              <Samples />
            </Suspense>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
