import React from "react";
import styles from "./Slider.module.scss";

interface ISlider {
  autoPlay: boolean;
  autoPlayTime: number;
  items: string[];
}

const Slider: React.FC<ISlider> = ({ items, autoPlay, autoPlayTime }) => {
  const [activeImg, setActiveImg] = React.useState(0);

  React.useEffect(() => {
    if (!autoPlay) return;
    const interval = setInterval(() => {
      setActiveImg((current) => {
        const res = current === items.length - 1 ? 0 : current + 1;
        return res;
      });
    }, autoPlayTime);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const prevImg = activeImg ? activeImg - 1 : items.length - 1;
  const nextImg = activeImg === items.length - 1 ? 0 : activeImg + 1;

  return (
    <div className={styles.slider}>
      <div className={`${styles.wrapper} ${styles.prev}`} key={prevImg}>
        <img className="{styles.pfone}" src={items[prevImg]} alt="Baner" />
      </div>

      <div className={styles.wrapper} key={activeImg}>
        <img className="{styles.pfone}" src={items[activeImg]} alt="Baner" />
      </div>

      <div className={`${styles.wrapper} ${styles.next}`} key={nextImg}>
        <img className="{styles.pfone}" src={items[nextImg]} alt="Baner" />
      </div>
    </div>
  );
};

export default Slider;
