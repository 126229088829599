import React from "react";
import { Outlet } from "react-router-dom";
import { Header, SideBar } from "../../components";
import Footer from "../../components/Footer";
import Preloader from "../Preloader";
import styles from "./Wrapper.module.scss";

const Wrapper: React.FC = () => {
  const [mobile, setMobile] = React.useState(true);
  const [showSideBar, setShowSideBar] = React.useState(true);
  /*Фикс ложного срабатывания в яндекс на мобилке*/

  const [size, setSize] = React.useState(2000);
  React.useEffect(() => {
    const handleResizeWindow = () => {
      if (window.innerWidth > 991) {
        setMobile(false);
        setShowSideBar(true);
        setSize(window.innerWidth);
      } else if (window.innerWidth < size) {
        setMobile(true);
        setShowSideBar(false);
        setSize(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [size]);
  const [loadartivle, setLoadArtivle] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoadArtivle(false);
    }, 2300);
  }, []);
  return (
    <div className={styles.wrapper}>
      {loadartivle && <Preloader />}
      {mobile && showSideBar && (
        <span className={styles.mask} onClick={() => setShowSideBar(false)} />
      )}
      <Header
        showSideBar={showSideBar}
        setShowSideBar={() => setShowSideBar((prevState) => !prevState)}
        mobile={mobile}
      />
      <SideBar
        showSideBar={showSideBar}
        mobile={mobile}
        hiddenSideBar={() => setShowSideBar(false)}
      />

      <div className={styles.main}>
        <div className={styles.content}>
          <Outlet />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
