import classNames from "classnames";
import React from "react";
import { SwiperContext } from "../";
import styles from "../swiper.module.scss";

const Dots: React.FC = () => {
  const { changeSlide, slidesCount, slideNumber } =
    React.useContext(SwiperContext);
  React.useContext(SwiperContext);
  return (
    <ul className={styles.dotsWrapper}>
      {[...new Array(slidesCount)].map((_, index) => (
        <li
          key={"dot-" + index}
          className={classNames(styles.dot, {
            [styles.active]: slideNumber === index,
          })}
          onClick={() => {
            if (changeSlide && slideNumber !== undefined) {
              changeSlide(index - slideNumber);
            }
          }}
        />
      ))}
    </ul>
  );
};

export default Dots;
