import classNames from "classnames";
import React from "react";
import Arrows from "./Arrows";
import Dots from "./Dots";
import styles from "./swiper.module.scss";

interface ISwiper {
  autoPlayTime?: number;
  /* Время переключения */
  items: string[];
  /* массив url адресов */
}

interface ISwiperContext {
  changeSlide: (number: number) => void;
  /* Функция выбора слайда */
  slidesCount: number;
  /* Количество слайдов */
  slideNumber?: number;
  /* Номер активного слайда */
  items: string[];
  /* массив url адресов */
}

export const SwiperContext = React.createContext<Partial<ISwiperContext>>({});

const Swiper: React.FC<ISwiper> = ({ items, autoPlayTime }) => {
  const [slide, setSlide] = React.useState(0);
  const [imgSrc, setImgSrc] = React.useState(items[slide] + "low.jpg");

  /*Выбор слайда */
  const changeSlide = (direction: number = 1) => {
    if (slide + direction < 0) setSlide(items.length - 1);
    else setSlide((direction + slide) % items.length);
  };
  React.useEffect(() => {
    const pressKey = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        changeSlide(-1);
      } else if (e.key === "ArrowRight") {
        changeSlide(1);
      }
    };
    window.addEventListener("keydown", pressKey);
    return () => {
      window.removeEventListener("keydown", pressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide]);

  /*Автоплей */
  React.useEffect(() => {
    if (!autoPlayTime || items.length < 2) return;

    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, slide]);

  // const handleTouchStart = (event: React.TouchEvent) => {
  //   setTouchPosition(event.touches[0].clientX);
  // };
  // const handleTouchMove = (event: React.TouchEvent) => {
  //   const currentTouchPosition = event.touches[0].clientX;
  //   if (touchPosition === null) return;
  //   const lenghtTouch = touchPosition - currentTouchPosition;
  //   if (lenghtTouch > 10) {
  //     changeSlide(1);
  //     setTouchPosition(null);
  //   }

  //   if (lenghtTouch < -10) {
  //     changeSlide(-1);
  //     setTouchPosition(null);
  //   }
  // };

  React.useEffect(() => {
    setImgSrc(items[slide] + "low.jpg");
    const img = new Image();
    img.src = items[slide] + ".jpg";
    img.onload = () => {
      setImgSrc(items[slide] + ".jpg");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide]);

  return (
    <div className={styles.wrapper}>
      <SwiperContext.Provider
        value={{
          changeSlide,
          slidesCount: items.length,
          slideNumber: slide,
        }}
      >
        <div className={classNames(styles.slide)}>
          <img
            src={imgSrc}
            alt="Swiper img"
            className={`${styles.image} ${
              imgSrc.indexOf("low.jpg") > 0 ? styles.loading : ""
            }`}
          />
          {imgSrc.indexOf("low.jpg") > 0 && (
            <img className={styles.svg} src="/Loading.png" alt="Loading" />
          )}
        </div>
        <Arrows />

        <Dots />
      </SwiperContext.Provider>
    </div>
  );
};

export default Swiper;
/*
    <div className={styles.slider}>
      <div className={`${styles.slide} ${styles.prev}`} key={prevImg}>
        <img className="{styles.pfone}" src={items[prevImg]} alt="Baner" />
      </div>

      <div className={`${styles.slide} ${styles.activeSlide}`} key={activeImg}>
        <img className="{styles.pfone}" src={items[activeImg]} alt="Baner" />
      </div>

      <div className={`${styles.wrapper} ${styles.next}`} key={nextImg}>
        <img className="{styles.pfone}" src={items[nextImg]} alt="Baner" />
      </div>
    </div>*/
