import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = () => (
  <div style={{ width: "100%", height: "auto", marginBottom: 60 }}>
    <ContentLoader
      speed={2}
      viewBox="0 0 340 210"
      backgroundColor="#605d6b"
      foregroundColor="#9ca2b4"
    >
      <rect x="3" y="0" rx="0" ry="0" width="90" height="67" />
      <rect x="3" y="70" rx="0" ry="0" width="90" height="67" />
      <rect x="3" y="140" rx="0" ry="0" width="90" height="67" />
      <rect x="96" y="117" rx="0" ry="0" width="67" height="90" />
      <rect x="96" y="24" rx="0" ry="0" width="67" height="90" />
      <rect x="166" y="140" rx="0" ry="0" width="90" height="67" />
      <rect x="166" y="15" rx="0" ry="0" width="90" height="122" />
      <rect x="259" y="-1" rx="0" ry="0" width="79" height="208" />
    </ContentLoader>
  </div>
);

export default Skeleton;
