import React from "react";
import { useLocation } from "react-router-dom";

import styles from "./SideBar.module.scss";
import SideBarContentHome from "./Content/SideBarContentHome";
import SidebarContentWork from "./Content/SidebarContentWork";
import SidebarContentArticle from "./Content/SideBarContentArticle";
import SideBarContentMobile from "./Content/SideBarContentMobile";
import classNames from "classnames";

const SideBar: React.FC<{
  mobile: boolean;
  showSideBar: boolean;
  hiddenSideBar: () => void;
}> = ({ mobile, showSideBar, hiddenSideBar }) => {
  const location = useLocation();
  React.useEffect(() => {
    if (showSideBar && mobile) document.body.style.overflow = "hidden";
    else {
      document.body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideBar, mobile]);
  const getContent = () => {
    if (mobile) return <SideBarContentMobile hiddenSideBar={hiddenSideBar} />;
    if (location.pathname === "/") return <SideBarContentHome />;
    if (location.pathname.indexOf("article") > 0)
      return <SidebarContentArticle />;
    if (location.pathname.indexOf("prototype")) return <SidebarContentWork />;
  };
  return (
    <aside
      className={classNames(styles.root, {
        [styles.mobile]: mobile,
        [styles.hidden]: !showSideBar,
      })}
    >
      {getContent()}
      <ul className={styles.footer}>
        <li>e-mail: eur9516@mail.ru</li>
        <li>
          тел: <a href="tel:+79233429516">+7 923-342-95-16</a>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
