export enum TypePrototypeEnum {
  all = "все",
  material = "материал",
  invoice = "фактура",
  functionality = "функциональность",
  interior = "интерьер",
  imitation = "имитация материала",
}
export const ListTypeEnum: PayloadSort[] = Object.entries(
  TypePrototypeEnum
).map(([key, value]) => ({ key, title: value, type: "type" }));

export interface PayloadSort {
  title: string;
  key?: string;
  parentType?: string;
  type: "subtype" | "type";
}
export enum SubtypePrototypeEnum {
  all = "все,все",
  putty = "шпатлевки,материал",
  plaster = "штукатурки,материал",
  paint = "краски,материал",
  textured = "фактурные,фактура",
  smooth = "гладкие,фактура",
  facade = "фасадные,функциональность",
  waterproof = "влагостойкие,функциональность",
  antivandal = "антивандальные,функциональность",
  interior = "интерьерные,функциональность",
  exotic = "экзотические,интерьер",
  univer = "универсальые,интерьер",
  beddin = "постельные,интерьер",
  bellhops = "коридорные,интерьер",
  tree = "дерево,имитация материала",
  marble = "мрамор,имитация материала",
  stone = "античный камень,имитация материала",
  velour = "велюр,имитация материала",
  desert = "пустыня,имитация материала",
}

export const ListSubtypeEnum: PayloadSort[] = Object.entries(
  SubtypePrototypeEnum
).map(([key, value]) => ({
  key,
  title: value.split(",", 2)[0],
  parentType: value.split(",", 2)[1],
  type: "subtype",
}));
export interface IfilterSlice {
  apartament: {
    countitems: number;
  };
  prototype: {
    searchValue: string;
    type: TypePrototypeEnum;
    subtype: string;
  };
  article: {
    category: string;
  };
}
