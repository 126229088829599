import React from "react";
import styles from "../Apartament.module.scss";
import { IApartament } from "../../../redux/feth/types";
import classNames from "classnames";
import Modal from "../../Modal";
import Swiper from "../../Swiper";
import { useInView } from "react-intersection-observer";
const Apartment: React.FC<IApartament & { fl: number }> = ({
  title,
  description,
  img,
  id,
  fl,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  if (img.length < 6 + fl) return <></>;
  return (
    <>
      <div
        ref={ref}
        onClick={() => setShowModal(true)}
        className={classNames(
          styles.apartament_grid,
          {
            [styles.animate]: !inView,
          },
          {
            [styles.grid2]: fl,
          }
        )}
      >
        <div className={`${styles.column} `}>
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio : styles.ratio3
            }`}
            src={img[0] + "low.jpg"}
            alt={title}
          />
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio : styles.ratio3
            }`}
            src={img[1] + "low.jpg"}
            alt={title}
          />
          {fl === 1 && (
            <img
              className={`${styles.apartament_img} ${styles.ratio}`}
              src={img[2] + "low.jpg"}
              alt={title}
            />
          )}
        </div>
        <div
          className={classNames(styles.column, { [styles.end]: id % 3 !== 1 })}
        >
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio3 : styles.ratio2
            }`}
            src={img[2 + fl] + "low.jpg"}
            alt={title}
          />
          {!fl && (
            <div
              className={classNames(styles.apartament_square, {
                [styles.apartament_square3]: id % 3 === 0,
              })}
            >
              {title}
            </div>
          )}
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio3 : styles.ratio2
            }`}
            src={img[3 + fl] + "low.jpg"}
            alt={title}
          />
        </div>
        <div className={classNames(styles.column, { [styles.end]: fl })}>
          {fl === 1 && (
            <div
              className={`${styles.apartament_square} ${styles.apartament_square2}`}
            >
              {title}
            </div>
          )}
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio2 : styles.ratio3
            }`}
            src={img[4 + fl] + "low.jpg"}
            alt={title}
          />
          <img
            className={`${styles.apartament_img} ${
              fl ? styles.ratio2 : styles.ratio3
            }`}
            src={img[5 + fl] + "low.jpg"}
            alt={title}
          />
        </div>
        <div className={styles.apartament_info}>
          <p> {description}</p>
          <div>Читать полностью...</div>
        </div>
      </div>
      {showModal && (
        <Modal
          onClickClose={() => setShowModal(false)}
          width={"90vw"}
          height={"90vh"}
        >
          <div
            style={{
              height: "100%",
              maxHeight: "90vh",
              backgroundColor: "#fff",
            }}
          >
            <Swiper items={img} />
          </div>

          <div
            style={{
              display: "block",
              textAlign: "center",
              padding: 20,
              color: "#faf4e1",
            }}
          >
            <div
              style={{
                marginBottom: 20,
                fontFamily: "Pacifico",
                fontSize: "2.6rem",
              }}
            >
              {title}
            </div>
            <p> {description}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Apartment;
