import React from "react";
import Article from "../components/Articles";
import Preloader from "../layout/Preloader";
import fetchArticle from "../redux/feth/asynkArticle";
import { store, useAppDispatch } from "../redux/store";
import { Helmet } from "react-helmet";

const Articles: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loadartivle, setLoadArtivle] = React.useState(false);
  React.useEffect(() => {
    const state = store.getState();
    if (
      !state.data.article.items.length &&
      state.filter.article.category === ""
    )
      dispatch(fetchArticle({}));
    setLoadArtivle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container">
        {loadartivle ? <Article /> : <Preloader />}
      </div>
    </>
  );
};

export default Articles;
