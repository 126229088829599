import React from "react";
import CommunicationForm from "../Form/Communication";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <div className={styles.root}>
      <CommunicationForm />
      <div className={styles.copyright}>
        Copyright © 2022. decor-master24.ru - портал декоративных решений для
        вашего дома. Все права защищены.
      </div>
    </div>
  );
}
export default Footer;
