import React from "react";
import ContentLoader from "react-content-loader";
import styles from "../Articles.module.scss";

const Skeleton = () => (
  <div className={styles.preview}>
    <ContentLoader
      speed={2}
      viewBox="0 0 300 400"
      backgroundColor="#605d6b"
      foregroundColor="#9ca2b4"
    >
      <rect x="0" y="0" rx="0" ry="0" width="300" height="400" />
    </ContentLoader>
  </div>
);

export default Skeleton;
