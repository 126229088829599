import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { actionFetch } from "../../../redux/feth/asyncAction";
import fetchArticle from "../../../redux/feth/asynkArticle";
import { TypePayload } from "../../../redux/feth/types";
import {
  setCategoryArticle,
  setSortPrototype,
} from "../../../redux/filter/slice";
import { ListTypeEnum, TypePrototypeEnum } from "../../../redux/filter/types";
import { RootState, useAppDispatch } from "../../../redux/store";
import { ListArticleCategory } from "../../Articles";
import styles from "../SideBar.module.scss";

const SideBarContentMobile: React.FC<{ hiddenSideBar: () => void }> = ({
  hiddenSideBar,
}) => {
  const dispatch = useAppDispatch();
  const { category } = useSelector((state: RootState) => state.filter.article);

  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const onClickLoadPrototype = (newtype: string) => {
    dispatch(
      actionFetch(
        true,
        1,
        TypePayload.PROTOTYPE,
        6,
        newtype === "все" ? "" : newtype,
        ""
      )
    );

    dispatch(setSortPrototype({ title: newtype, type: "type" }));
    if (pathname.indexOf("prototype") <= 0) navigate("/prototype");
  };
  return (
    <ul className={styles.nav}>
      <li className={styles.item}>
        <Link
          className={classNames(styles.link, {
            [styles.active]: pathname === "/",
          })}
          to="/"
          onClick={hiddenSideBar}
        >
          Главная
        </Link>
      </li>
      <li className={styles.item}>
        <Link
          className={classNames(styles.link, {
            [styles.active]: pathname.indexOf("prototype") > 0,
          })}
          to={`/prototype`}
          onClick={hiddenSideBar}
        >
          Образцы
        </Link>

        {
          <ul className={styles.subnav}>
            {ListTypeEnum.filter((e) => {
              return e.title !== TypePrototypeEnum.all;
            }).map(
              (obj) =>
                obj.key && (
                  <li
                    key={obj.key}
                    className={styles.sublink}
                    onClick={() => {
                      onClickLoadPrototype(obj.title);
                      hiddenSideBar();
                    }}
                  >
                    {obj.title}
                  </li>
                )
            )}
          </ul>
        }
      </li>
      <li className={styles.item}>
        <Link
          className={classNames(styles.link, {
            [styles.active]: pathname.indexOf("article") > 0,
          })}
          to={`/article`}
          onClick={() => {
            hiddenSideBar();
          }}
        >
          Статьи
        </Link>

        <ul className={styles.subnav}>
          {ListArticleCategory.map(
            (obj) =>
              obj.key && (
                <li
                  key={obj.key}
                  onClick={() => {
                    dispatch(fetchArticle({ category: obj.key }));
                    dispatch(setCategoryArticle(obj.key));
                    if (pathname !== "/article") navigate("/article");
                    hiddenSideBar();
                  }}
                  className={classNames(styles.sublink, {
                    [styles.active]: obj.key === category,
                  })}
                >
                  {obj.value}
                </li>
              )
          )}
        </ul>
      </li>
    </ul>
  );
};

export default SideBarContentMobile;
