import React from "react";
import Button from "../Button";
import Prototype from "./PrototypeBlock";
import { useSelector } from "react-redux";
import { actionFetch } from "../../redux/feth/asyncAction";
import { Status, TypePayload } from "../../redux/feth/types";
import { RootState, useAppDispatch } from "../../redux/store";
import styles from "./Prototype.module.scss";
import Sort from "./Sort";
import {
  SubtypePrototypeEnum,
  TypePrototypeEnum,
} from "../../redux/filter/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Skeleton from "./PrototypeBlock/Skeleton";

function Prototypes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sortFixed, setsortFixed] = React.useState(false);
  const sectionRef = React.useRef<HTMLElement>(null);
  const path = location.pathname.indexOf("prototype") > 0 ? true : false;
  const listenToScroll = () => {
    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (
      sectionRect &&
      sortFixed &&
      (sectionRect.top > -80 || sectionRect.bottom < 100)
    )
      setsortFixed(false);
    if (
      sectionRect &&
      !sortFixed &&
      sectionRect.top < -80 &&
      sectionRect.bottom > 300
    )
      setsortFixed(true);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    listenToScroll();
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFixed]);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const { items, nextPage, status } = useSelector(
    (state: RootState) => state.data.prototype
  );
  const { type, subtype } = useSelector(
    (state: RootState) => state.filter.prototype
  );
  React.useEffect(() => {
    if (status === Status.SUCCESS && inView) onClickLoadPrototype(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    onClickLoadPrototype(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, subtype]);

  const onClickLoadPrototype = (update: boolean) => {
    dispatch(
      actionFetch(
        update,
        update ? 1 : items.length / 6 + 1,
        TypePayload.PROTOTYPE,
        6,
        Object.keys(TypePrototypeEnum)[
          Object.values(TypePrototypeEnum).indexOf(type)
        ],
        Object.keys(SubtypePrototypeEnum)[
          Object.values(SubtypePrototypeEnum).indexOf(
            (subtype + "," + type) as SubtypePrototypeEnum
          )
        ]
      )
    );
    if (update && sectionRef.current)
      window.scroll(0, sectionRef.current.offsetTop);
  };
  const getTitle = () => {
    if (location.pathname === "/") return <h2>Галерея образцов</h2>;
    return <h1>Галерея образцов</h1>;
  };
  return (
    <section ref={sectionRef}>
      {getTitle()}
      <h3 style={{ marginBottom: sortFixed ? 50 : 10 }}>
        В галерее представленны примеры возможных декоративных покрытий
      </h3>

      <Sort fixed={sortFixed} />

      <div className={styles.wrapper}>
        {items.map((obj) => (
          <Prototype key={obj._id} {...obj} />
        ))}
        {status === Status.LOADING &&
          [...new Array(3)].map((_, index) => <Skeleton key={"PS" + index} />)}
        {status === Status.ERROR && (
          <div style={{ marginBottom: 50, width: "100%", textAlign: "center" }}>
            Возникла ошибка при загрузке. Попробуйте перезагрузить компонент{" "}
            <Button onClick={() => onClickLoadPrototype(true)}>Обновить</Button>
          </div>
        )}
      </div>

      {path ? (
        nextPage &&
        status === Status.SUCCESS && (
          <div className={styles.wrapper} ref={ref}>
            {[...new Array(3)].map((_, index) => (
              <Skeleton key={"PS" + index} />
            ))}{" "}
          </div>
        )
      ) : (
        <div className={styles.footer}>
          {nextPage && status === Status.SUCCESS && (
            <Button onClick={() => onClickLoadPrototype(false)}>
              Загрузить больше...
            </Button>
          )}
          <Button
            accent
            onClick={() => {
              navigate("/prototype");
            }}
          >
            Перейти к каталогу
          </Button>
        </div>
      )}
    </section>
  );
}

export default Prototypes;
