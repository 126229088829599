import React from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { actionFetch } from "../../redux/feth/asyncAction";
import { Status, TypePayload } from "../../redux/feth/types";
import { RootState, useAppDispatch } from "../../redux/store";
import styles from "./Apartament.module.scss";
import Apartment from "./ApartamentBlock";
import Skeleton from "./ApartamentBlock/Skeleton";

function Apartaments() {
  const dispatch = useAppDispatch();
  const { items, nextPage, status } = useSelector(
    (state: RootState) => state.data.apartament
  );

  React.useEffect(() => {
    dispatch(actionFetch(true, 1, TypePayload.APARTAMENT, 3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickLoadApartament = () => {
    dispatch(
      actionFetch(false, items.length / 3 + 1, TypePayload.APARTAMENT, 3)
    );
  };
  const getTitle = () => {
    return <h1>Галерея красочных объектов</h1>;
  };
  return (
    <section>
      {getTitle()}

      <h3>В галерее представленны различные примеры выполненных работ</h3>
      {items.map((obj) => (
        <Apartment key={obj.id} {...obj} fl={(obj.id % 3) - 2 == 0 ? 1 : 0} />
      ))}
      {status === Status.LOADING &&
        [...new Array(3)].map((_, index) => <Skeleton key={"AS" + index} />)}
      {status === Status.ERROR && (
        <div style={{ marginBottom: 50, width: "100%", textAlign: "center" }}>
          Возникла ошибка при загрузке. Попробуйте перезагрузить компонент{" "}
          <Button onClick={onClickLoadApartament}>Обновить</Button>
        </div>
      )}
      <div className={styles.footer}>
        {nextPage && (
          <Button onClick={onClickLoadApartament}>Загрузить больше...</Button>
        )}
      </div>
    </section>
  );
}

export default Apartaments;
