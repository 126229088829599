import React from "react";
import { SwiperContext } from "../";
import styles from "../swiper.module.scss";

const Arrows: React.FC = () => {
  const { changeSlide } = React.useContext(SwiperContext);
  return (
    <div className={styles.arrowsWrapper}>
      <div
        style={{
          width: 40,
          height: 50,
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => {
          if (changeSlide) changeSlide(-1);
        }}
      >
        <div className={`${styles.arrow} ${styles.prev}`}>Prev</div>
      </div>
      <div
        style={{
          width: 40,
          height: 50,
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => {
          if (changeSlide) changeSlide(1);
        }}
      >
        <div className={`${styles.arrow} ${styles.next}`}>Next</div>
      </div>
    </div>
  );
};

export default Arrows;
