import React from "react";
import styles from "../Prototype.module.scss";
import ContentLoader from "react-content-loader";
function Skeleton() {
  return (
    <div className={styles.item}>
      <ContentLoader
        speed={2}
        viewBox="0 0 300 400"
        backgroundColor="#605d6b"
        foregroundColor="#9ca2b4"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
      <span className={styles.square} style={{ opacity: 0.5 }}></span>
    </div>
  );
}

export default Skeleton;
