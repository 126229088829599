import classNames from "classnames";
import { useSelector } from "react-redux";
import { actionFetch } from "../../../redux/feth/asyncAction";
import { TypePayload } from "../../../redux/feth/types";
import { setSortPrototype } from "../../../redux/filter/slice";
import styles from "../SideBar.module.scss";

import {
  ListSubtypeEnum,
  ListTypeEnum,
  SubtypePrototypeEnum,
  TypePrototypeEnum,
} from "../../../redux/filter/types";
import { RootState, useAppDispatch } from "../../../redux/store";

function SidebarContentWork() {
  const { subtype, type } = useSelector(
    (state: RootState) => state.filter.prototype
  );
  const dispatch = useAppDispatch();
  const onClickLoadPrototype = (newtype: string, newsubtype: string) => {
    dispatch(
      actionFetch(true, 1, TypePayload.PROTOTYPE, 6, newtype, newsubtype)
    );
  };
  return (
    <ul className={styles.nav}>
      {ListTypeEnum.filter((e) => {
        return e.title !== TypePrototypeEnum.all;
      }).map((obj) => (
        <li key={obj.key} className={styles.item}>
          <div
            className={classNames(styles.link, {
              [styles.active]: obj.title === type,
            })}
            onClick={() => {
              dispatch(setSortPrototype(obj));
              onClickLoadPrototype(obj.title, "");
            }}
          >
            {obj.title}
          </div>
          {ListSubtypeEnum.filter((e) => {
            return e.parentType === obj.title;
          }) && (
            <ul className={styles.subnav}>
              {ListSubtypeEnum.filter((e) => {
                return (
                  e.parentType === obj.title &&
                  e.title !== SubtypePrototypeEnum.all
                );
              }).map((subnav, index) => (
                <li
                  key={"subnav" + index}
                  className={classNames(styles.sublink, {
                    [styles.active]: subnav.title === subtype,
                  })}
                  onClick={() => {
                    dispatch(setSortPrototype(subnav));
                    onClickLoadPrototype(obj.title, subnav.title);
                  }}
                >
                  {subnav.title}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

export default SidebarContentWork;
