import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Sort from "../../Sort";
import {
  ListTypeEnum,
  ListSubtypeEnum,
  TypePrototypeEnum,
} from "../../../redux/filter/types";

import styles from "../Prototype.module.scss";
import classNames from "classnames";

const Prototype: React.FC<{
  fixed: boolean;
}> = ({ fixed }) => {
  const sortPrortotype = useSelector(
    (state: RootState) => state.filter.prototype
  );

  return (
    <div className={classNames([styles.sort], { [styles.fixed]: fixed })}>
      <div className={styles.filters}>
        <Sort
          title={"Признак сортировки"}
          activeSort={{ title: sortPrortotype.type, type: "type" }}
          sortList={ListTypeEnum}
        />
        <Sort
          title={"Группа покрытий"}
          activeSort={{ title: sortPrortotype.subtype, type: "subtype" }}
          sortList={
            sortPrortotype.type === TypePrototypeEnum.all
              ? ListSubtypeEnum
              : ListSubtypeEnum.filter(
                  (e) => e.parentType === sortPrortotype.type
                )
          }
        />
      </div>
    </div>
  );
};

export default Prototype;
