export type IAdvantage = {
  _id: number;
  img_url: string;
  text: number;
};
export type IApartament = {
  title: string;
  description: string;
  img: string[];
  id: number;
};
export type IArticle = {
  title: string;
  preview: string;
  category: string;
  img: string;
  text?: string;
  date: string;
  tags: { title: string; type: string }[];
  _id: string;
};
export type IPrototype = {
  description: string;
  _id: string;
  title: string;
  img_preview: string;
  img_all: string[];
};

export enum Status {
  LOADING = "loading",
  SUCCESS = "completed",
  ERROR = "error",
}

export enum TypePayload {
  APARTAMENT = "apartament",
  PROTOTYPE = "prototype",
  ADVANTAGE = "advantage",
  ARTICLE = "article",
}

export type SearchParams = {
  update: boolean;
  typeItems: TypePayload;
  page: string;
  limit: string;
  decorType?: string;
  decorSubType?: string;
};

export type IcontentSlice = {
  apartament: {
    items: IApartament[];
    status: Status;
    nextPage: boolean;
  };
  article: {
    items: IArticle[];
    status: Status;
  };
  prototype: {
    items: IPrototype[];
    status: Status;
    nextPage: boolean;
  };
  advantage: {
    items: IAdvantage[];
    status: Status;
    nextPage: boolean;
  };
};
