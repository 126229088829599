import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";
import { IArticle } from "./types";

const fetchArticle = createAsyncThunk<
  IArticle[],
  { category?: string; tags?: string }
>("article/fetchArticleStatus", async (params) => {
  const { category, tags } = params;
  const { data } = await axios.get<IArticle[]>(`/article`, {
    headers: {
      category: category || "",
      tags: tags || "",
    },
  });

  return data;
});

export default fetchArticle;
