import React from "react";
import styles from "./Intro.module.scss";
import axios from "axios";

import Slider from "../Slider";
import Easel from "../Easel";

type Iaxios = {
  intro_back: string[];
  intro_easel: string[];
};

const Intro: React.FC = () => {
  const [items, setItems] = React.useState<string[]>([]);
  const [easel, setEasel] = React.useState<string[]>([]);
  const [width, setwidth] = React.useState<string>();
  const introRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    axios.get<Iaxios>(`/db.json`).then(({ data }) => {
      setItems(data.intro_back);
      setEasel(data.intro_easel);
    });
  }, []);
  React.useEffect(() => {
    const handleResizeWindow = () => {
      let koef = 25;

      if (introRef.current) {
        if (introRef.current.clientWidth < 1150) koef = 15;
        if (introRef.current.clientHeight < 550) koef = 0;
        setwidth(
          (introRef.current.clientHeight / introRef.current.clientWidth) *
            koef +
            "%"
        );
      }
    };
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [easel.length]);

  return (
    <div ref={introRef} className={styles.intro}>
      {items.length && (
        <Slider items={items} autoPlay={true} autoPlayTime={7000} />
      )}

      {width && <Easel items={easel} autoPlayTime={8000} width={width} />}
    </div>
  );
};

export default Intro;
