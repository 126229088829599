import React from "react";
import Swiper from "../../Swiper";
import styles from "../Prototype.module.scss";

const ModalContent: React.FC<{
  items: string[];
  description: string;
  title: string;
}> = ({ items, description, title }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.slider}>
        <Swiper items={items} />
      </div>

      <div className={styles.description}>
        <div className={styles.title}>{title}</div>
        {description}
      </div>
    </div>
  );
};

export default ModalContent;
