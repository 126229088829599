import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Страница не найдена</h1>
      <Button onClick={() => navigate("/")}>Вернуться на главную</Button>
    </div>
  );
}

export default NotFound;
