import React from "react";
import styles from "./Button.module.scss";
import classnames from "classnames";
interface IButton {
  accent?: boolean;
  children: string;
  type?: "submit" | "reset" | "button";
  onClick?: () => void;
}
const Button: React.FC<IButton> = ({ accent, children, onClick, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classnames(styles.root, { [`${styles.accent}`]: accent })}
    >
      {children}
    </button>
  );
};

export default Button;
