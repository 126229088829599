import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IfilterSlice,
  PayloadSort,
  SubtypePrototypeEnum,
  TypePrototypeEnum,
} from "./types";

const initialState: IfilterSlice = {
  apartament: {
    countitems: 0,
  },
  prototype: {
    searchValue: "",
    type: TypePrototypeEnum.all,
    subtype: SubtypePrototypeEnum.all.split(",", 2)[0],
  },
  article: {
    category: "",
  },
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setCountApartament(state, action: PayloadAction<number>) {
      state.apartament.countitems = action.payload;
    },
    setCategoryArticle(state, action: PayloadAction<string>) {
      state.article.category = action.payload;
    },
    setSortPrototype(state, action: PayloadAction<PayloadSort>) {
      switch (action.payload.type) {
        case "subtype": {
          state.prototype.subtype = action.payload.title;
          if (action.payload.parentType)
            state.prototype.type = action.payload
              .parentType as TypePrototypeEnum;

          break;
        }
        case "type": {
          state.prototype.type = action.payload.title as TypePrototypeEnum;
          state.prototype.subtype = SubtypePrototypeEnum.all.split(",", 2)[0];
          break;
        }
      }
    },
  },
});

export const { setCountApartament, setSortPrototype, setCategoryArticle } =
  filterSlice.actions;

export default filterSlice.reducer;
