import { createSlice } from "@reduxjs/toolkit";
import fetchItems from "./asyncAction";
import fetchArticle from "./asynkArticle";

import {
  IAdvantage,
  IApartament,
  IcontentSlice,
  IPrototype,
  Status,
  TypePayload,
} from "./types";

const initialState: IcontentSlice = {
  apartament: {
    items: [],
    nextPage: false,
    status: Status.LOADING, // loading | success | error
  },
  article: {
    items: [],
    status: Status.LOADING, // loading | success | error
  },
  prototype: {
    items: [],
    nextPage: false,

    status: Status.LOADING, // loading | success | error
  },
  advantage: {
    items: [],
    nextPage: false,

    status: Status.LOADING, // loading | success | error
  },
};

// type NewType = (data:IAdvantage[]|  IApartament[]| IPrototype[]) => IAdvantage[] | IApartament[] | IPrototype[];

// const getData:NewType=(data)=>{
//   const newData = [
//     ...state.advantage.items,
//     ...(<IAdvantage[]>data),
//   ];
// }
const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArticle.pending, (state, action) => {
      state.article.items = [];
      state.article.status = Status.LOADING;
    });

    builder.addCase(fetchArticle.fulfilled, (state, action) => {
      state.article.items = action.payload;

      state.article.status = Status.SUCCESS;
    });
    builder.addCase(fetchArticle.rejected, (state, action) => {
      state.article.items = [];
      state.article.status = Status.ERROR;
    });

    builder.addCase(fetchItems.pending, (state, action) => {
      switch (action.meta.arg.typeItems) {
        case TypePayload.ADVANTAGE: {
          if (action.meta.arg.update) state.advantage.items = [];
          state.advantage.status = Status.LOADING;
          break;
        }
        case TypePayload.APARTAMENT: {
          if (action.meta.arg.update) state.apartament.items = [];
          state.apartament.status = Status.LOADING;
          break;
        }
        case TypePayload.PROTOTYPE: {
          if (action.meta.arg.update) state.prototype.items = [];
          state.prototype.status = Status.LOADING;
          break;
        }
      }
    });

    builder.addCase(fetchItems.fulfilled, (state, action) => {
      switch (action.payload.typeItems) {
        case TypePayload.ADVANTAGE: {
          if (action.payload.update) state.advantage.items = [];
          const newData = [
            ...state.advantage.items,
            ...(action.payload.data as IAdvantage[]),
          ];
          state.advantage.items = newData;
          state.advantage.nextPage = action.payload.nextPage;

          state.advantage.status = Status.SUCCESS;
          break;
        }
        case TypePayload.APARTAMENT: {
          if (action.payload.update) state.apartament.items = [];
          const newData = [
            ...state.apartament.items,
            ...(action.payload.data as IApartament[]),
          ];
          state.apartament.items = newData;
          state.apartament.nextPage = action.payload.nextPage;

          state.apartament.status = Status.SUCCESS;
          break;
        }
        case TypePayload.PROTOTYPE: {
          if (action.payload.update) state.prototype.items = [];
          const newData = [
            ...state.prototype.items,
            ...(action.payload.data as IPrototype[]),
          ];
          state.prototype.items = newData;
          state.prototype.nextPage = action.payload.nextPage;

          state.prototype.status = Status.SUCCESS;
          break;
        }
      }
    });

    builder.addCase(fetchItems.rejected, (state, action) => {
      switch (action.meta.arg.typeItems) {
        case TypePayload.ADVANTAGE: {
          state.advantage.status = Status.ERROR;
          break;
        }
        case TypePayload.APARTAMENT: {
          state.apartament.status = Status.ERROR;
          break;
        }
        case TypePayload.PROTOTYPE: {
          state.prototype.status = Status.ERROR;
          break;
        }
      }
    });
  },
});

export default contentSlice.reducer;
