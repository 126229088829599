import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import styles from "../Articles.module.scss";
import parse from "html-react-parser";
import axios from "../../../redux/axios";
import { IArticle } from "../../../redux/feth/types";
import fetchArticle from "../../../redux/feth/asynkArticle";
import Preloader from "../../../layout/Preloader";
import { setCategoryArticle } from "../../../redux/filter/slice";

interface ArticleProps {
  _id: string;
}
const Article: React.FC<ArticleProps> = ({ _id }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [data, setData] = React.useState<
    { article: IArticle; relatedArticle: IArticle[] } | undefined
  >();
  React.useEffect(() => {
    axios
      .get<{ article: IArticle; relatedArticle: IArticle[] }>(`/article/${_id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        alert("Не удалось открыть статью");
        navigate(-1);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data?.article)
    return (
      <div className={styles.post}>
        <Preloader />
      </div>
    );
  return (
    <article className={styles.post}>
      <div className={styles.back}>
        <div onClick={() => navigate(-1)}>вернуться назад</div>
      </div>

      <div className={styles.header}>
        <h1 className={styles.title}>{data.article.title}</h1>
        <ul className={styles.info}>
          <li className={styles.item}>{data.article.date}</li>
          {data.article.tags.map((obj, index) => (
            <li
              key={`tags` + index}
              className={`${styles.item} ${styles.link}`}
              onClick={() => {
                dispatch(setCategoryArticle(obj.type));
                dispatch(
                  fetchArticle({
                    tags: obj.type,
                  })
                );
                navigate("/article");
              }}
            >
              {obj.title}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.text}>
        {data.article.text && parse(data.article.text)}
      </div>

      <h3 className={styles.title}>Интересно почитать</h3>
      <ul className={styles.related}>
        {data.relatedArticle.map((obj, index) => (
          <li key={`related` + index} className={styles.item}>
            <Link to={`/article/${obj._id}`}>
              <div className={styles.title}>{obj.title}</div>
              <div className={styles.date}>{obj.date}</div>
            </Link>
          </li>
        ))}
      </ul>
    </article>
  );
};
export default Article;
